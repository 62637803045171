@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Gilroy";
  src: url("/fonts/Gilroy/Gilroy-Light.woff2") format("woff2"), url("/fonts/Gilroy/Gilroy-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("/fonts/Gilroy/Gilroy-ExtraBold.woff2") format("woff2"),
    url("/fonts/Gilroy/Gilroy-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("/fonts/Gilroy/Gilroy-Medium.woff2") format("woff2"), url("/fonts/Gilroy/Gilroy-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("/fonts/Gilroy/Gilroy-Black.woff2") format("woff2"), url("/fonts/Gilroy/Gilroy-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("/fonts/Gilroy/Gilroy-Bold.woff2") format("woff2"), url("/fonts/Gilroy/Gilroy-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("/fonts/Gilroy/Gilroy-SemiBold.woff2") format("woff2"),
    url("/fonts/Gilroy/Gilroy-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("/fonts/Gilroy/Gilroy-Regular.woff2") format("woff2"),
    url("/fonts/Gilroy/Gilroy-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --rt-opacity: 1 !important;
}

body {
  font-family: "Gilroy", Helvetica, sans-serif !important;
}

.infinite-scroll-component__outerdiv {
  height: 100%;
}

.auth-button {
  @apply rounded-lg py-2 bg-secondary;
}

.auth-button:hover {
  @apply bg-opacity-75;
}

.base-tooltip {
  border-radius: 0 !important;
  background-color: rgb(255 198 73) !important;
  color: rgb(4 9 50) !important;
  padding: 0.4rem 1rem !important;
  font-size: 12px !important;
}
